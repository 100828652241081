<template>
  <v-card class="elevation-3 loginForm">
    <v-toolbar dark color="primary">
      <v-img
        class="shrink mr-2"
        contain
        :src="require('@/assets/logo.png')"
        transition="scale-transition"
      />

      <v-toolbar-title>{{ $t("requestPasswordChange") }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form v-if="!showText" @submit.prevent>
        <v-text-field
          v-model="password"
          prepend-icon="mdi-lock"
          name="password"
          :label="$t('password')"
          type="password"
          outlined
        ></v-text-field>
        <v-text-field
          v-model="confirmPassword"
          prepend-icon="mdi-lock"
          name="password"
          :label="$t('password')"
          type="password"
          outlined
          :rules="[arePasswordsEqual || `Passwords doesn't match!`]"
        ></v-text-field>
      </v-form>
      <p v-else>Something went wrong.</p>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        v-if="!showText"
        type="submit"
        :loading="loading"
        color="primary"
        large
        rounded
        @click="send"
        >{{ $t("send") }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
<script>
import { passwordChange } from "@/api";
export default {
  data: () => ({
    password: "",
    confirmPassword: "",
    loading: false,
    showText: false,
  }),
  computed: {
    arePasswordsEqual() {
      return this.password === this.confirmPassword;
    },
  },
  methods: {
    async send() {
      if (
        this.password.trim() !== "" &&
        this.arePasswordsEqual &&
        this.$route.query.token
      ) {
        const res = await passwordChange(
          this.$route.query.token,
          this.password
        );
        if (res && res.token) {
          this.$store.commit("SET_TOKEN", res.token);
          this.$router.push("/");
        } else {
          console.log("TU");
          this.showText = true;
        }
      }
    },
  },
};
</script>

<style>
.loginForm header {
  height: 124px !important;
  padding-top: 20px;
  box-shadow: none !important;
}
.loginForm .v-toolbar__content {
  display: grid;
  text-align: center;
  grid-gap: 30px;
}

.loginForm .v-card__actions {
  padding: 20px;
}
.loginForm .v-card__actions button {
  font-size: 1em;
  padding: 0 20px !important;
  font-weight: bold;
}
.loginForm .v-card__text {
  padding: 26px 16px 0 16px;
}
</style>
